import React from 'react'
import MarkdownToSections from '../helpers/MarkdownToSections'
import PageContent from '../helpers/PageContent'
import GetData from '../helpers/GetData'
import logo from '../../assets/images/csis_logo_black.svg'
import CloseMenu from '../helpers/CloseMenu'

class PostApp extends React.Component {
  constructor(props) {
    super(props)
    const { siteStructure, page } = this.props

    const pageContent = PageContent(siteStructure, page, GetData(page))

    this.state = {
      siteStructure,
      page,
      pageContent
    }
  }

  componentDidMount() {
    document.title =
      'Application | Center for Strategic and International Studies'
  }

  componentWillUnmount() {
    const triggers = Array.from(
      document.querySelectorAll('.menu-trigger.is-active')
    )
    triggers.forEach(trigger => {
      const target = document.querySelector(trigger.dataset.target)
      CloseMenu(trigger, target)
    })

    window.scrollTo({
      top: 0
    })
  }
  printHeading = job => {
    return (
      <header className="job-header">
        <div>
          <a
            href={`/opportunities/${job.jobId['#text']}`}
            className="backward large block"
          >
            Back to Job Description
          </a>
        </div>
        <h1 itemProp="title">Application</h1>
      </header>
    )
  }
  printForm = job => {
    console.log(
      `https://jobs.silkroad.com/CSIS/Careers/MultiForm/Apply?jobId=${
        job.jobId['#text']
      }&noFormRedirect=True`
    )
    return (
      <section className="application">
        <div className="application__container">
          <div className="loader" />
          <iframe
            id="application"
            onLoad={this.handleLoad}
            title="Application"
            src={`https://jobs.silkroad.com/CSIS/Careers/MultiForm/Apply?jobId=${
              job.jobId['#text']
            }&noFormRedirect=True`}
            frameBorder="0"
            sandbox="allow-scripts allow-same-origin allow-forms"
          />
        </div>
      </section>
    )
  }

  handleLoad = () => {
    const loader = document.querySelector('.loader')
    if (loader) loader.remove()
  }
  render() {
    const { jobData } = this.props
    const { pageContent, page } = this.state

    const job = jobData.find(
      job => job.jobId['#text'] === this.props.match.params.id
    )

    return (
      <main className={page}>
        {job ? this.printHeading(job) : <header className="job-header" />}
        <article className="job-content">
          {job ? this.printForm(job) : <div className="job-content__body" />}
          <aside className="job-content__about">
            {job ? (
              <a
                className="btn dark apply"
                href={`/opportunities/${job.jobId['#text']}`}
              >
                Back
              </a>
            ) : (
              ''
            )}
            <h3>About Us</h3>
            <img src={logo} className="Page-logo" alt="logo" />
            {MarkdownToSections(pageContent)}
          </aside>
        </article>
      </main>
    )
  }
}

if (!('remove' in Element.prototype)) {
  Element.prototype.remove = function() {
    if (this.parentNode) {
      this.parentNode.removeChild(this)
    }
  }
}

export default PostApp
