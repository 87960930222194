export default {
  opportunities: {
    templated: false,
    to: '/opportunities',
    index: 1,
    header_title: 'Opportunities',
    footer_title: 'Opportunity Listings'
  },
  post: {
    templated: false,
    menu: false
  },
  faq: {
    to: '/faq',
    index: 3,
    header_title: 'FAQ',
    footer_title: 'FAQ',
    content: []
  },
  homepage: {
    index: 0,
    menu: false,
    to: '/',
    header_title: 'HOME LOGO',
    content: []
  },
  internships: {
    to: '/internships',
    index: 2,
    header_title: 'Internships',
    footer_title: 'Internships',
    content: []
  },
  errorpage: {
    menu: false
  }
}
