import React from 'react'
import { Redirect } from 'react-router-dom'
import MarkdownToSections from '../helpers/MarkdownToSections'
import PageContent from '../helpers/PageContent'
import GetData from '../helpers/GetData'
import logo from '../../assets/images/csis_logo_black.svg'
import CloseMenu from '../helpers/CloseMenu'
import PostFooterNav from '../components/PostFooterNav'
import SocialShare from '../components/SocialShare'

class Post extends React.Component {
  constructor(props) {
    super(props)
    const { siteStructure, page } = this.props

    const pageContent = PageContent(siteStructure, page, GetData(page))

    this.state = {
      siteStructure,
      page,
      pageContent
    }
  }

  componentDidMount() {
    document.title = 'Careers | Center for Strategic and International Studies'
  }

  componentWillUnmount() {
    const triggers = Array.from(
      document.querySelectorAll('.menu-trigger.is-active')
    )
    triggers.forEach(trigger => {
      const target = document.querySelector(trigger.dataset.target)
      CloseMenu(trigger, target)
    })

    window.scrollTo({
      top: 0
    })
  }

  capitalize = string => {
    const capitalized =
      string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    const heading = capitalized.replace(':', '')

    return string.toLowerCase().includes('job')
      ? `<h2>${heading}</h2>`
      : `<h3>${heading}</h3>`
  };

  stripExcess = string => {
    return string
      .replace(/(<br>)/g, '<br />')
      .replace(
        /(<p><strong>&nbsp;<\/strong><\/p>|<p>&nbsp;<\/p>|&nbsp;&nbsp;|<h3>&nbsp;<\/h3>|<br \/> <br \/>)/g,
        ''
      )
      .replace(/(:|<strong><\/strong>|<p><\/p>)/g, '')
      .replace(/(<p><br \/>)/g, '<p>')
  };

  convertURLs = string => {
    const test = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g
    return string
      .replace(
        /<a href=/gi,
        '<a target="_blank" rel="noopener noreferrer" href='
      )
      .replace(/csiscareers.silkroad.com/g, 'careers.csis.org')
      .replace(/https\/\//g, 'https://')
      .replace(/ www/g, ' https://www')
      .replace(test, match => {
        return `<a href="${match}" target="_blank" rel="noreferrer nofollow">${match}</a>`
      })
  };

  formatDescription = description => {
    let contents = description

    const splitters = [
      'JOB SUMMARY',
      'APPLICATION MATERIALS',
      'DESIRED QUALIFICATIONS',
      'HIGHLY DESIRABLE SKILLS',
      'KNOWLEDGE AND EDUCATION',
      'APPLICATION INSTRUCTIONS',
      'JOB RESPONSIBILITIES',
      'PRIMARY RESPONSIBILITIES',
      'REQUIRED SKILLS AND EXPERIENCE',
      'STRONGLY PREFERRED QUALIFICATIONS',
      'KNOWLEDGE, EDUCATION, AND EXPERIENCE',
      'ESSENTIAL DUTIES AND RESPONSIBILITIES',
      'APPLICATION INSTRUCTIONS AND DEADLINES',
      'SOME TECHNOLOGIES WE USE AT THE iDEAS LAB',
      'PHYSICAL REQUIREMENTS AND WORK CONDITIONS',
      'REQUIRED KNOWLEDGE, EDUCATION, AND EXPERIENCE',
      'HOW TO APPLY'
    ]

    let headingsWithIndex = []

    splitters.forEach(splitter => {
      const regEx = new RegExp(
        `${splitter}:|<strong>${splitter}</strong>`,
        'i'
      )

      if (typeof contents === 'string') {
        if (regEx.test(contents))
          headingsWithIndex.push({
            index: description.indexOf(splitter),
            heading: this.capitalize(splitter)
          })

        contents = contents.split(regEx)
      } else {
        contents = contents.reduce((acc, cur) => {
          if (regEx.test(cur))
            headingsWithIndex.push({
              index: description.indexOf(splitter),
              heading: this.capitalize(splitter)
            })
          const split = cur.split(regEx)

          return typeof acc === 'string'
            ? [acc].concat(split)
            : acc.concat(split)
        })
        contents = contents.flatten ? contents.flatten() : [contents]
      }
    })
    let headings = headingsWithIndex
      .sort((a, b) => a.index - b.index)
      .map(h => h.heading)

    const sections = contents.map((content, index) => {
      return {
        heading: headings[index - 1],
        content: this.convertURLs(this.stripExcess(content))
      }
    })

    return sections.length > 1 ? sections.slice(1) : sections
  };

  printHeading = job => {
    return (
      <header className="job-header">
        <div>
          <a href="/opportunities" className="backward large block">
            Back to Job Listings
          </a>
        </div>
        <h1 itemProp="title"> {job.title['#cdata-section']} </h1>

        <div
          className="job__location"
          itemProp="jobLocation"
          itemScope
          itemType="http://schema.org/Place"
        >
          {job.jobLocation.municipality['#cdata-section']},{' '}
          {job.jobLocation.region['#cdata-section']}
        </div>
        <div className="job__details">
          <div className="job-meta">
            <div className="job__details-tracking">
              <span className="job-meta__label">Tracking Code:</span>{' '}
              {job.trackingCode['#cdata-section']}
            </div>
            <div className="job__details-category">
              <span className="job-meta__label">Category:</span>{' '}
              {job.category['#cdata-section']}
            </div>
            <div className="job__details-type" itemProp="employmentType">
              <span className="job-meta__label">Position Type:</span>{' '}
              {job.positionType['#cdata-section']}
            </div>
            <div className="job__details-date" itemProp="datePosted">
              <span className="job-meta__label">Posted:</span>{' '}
              {new Date(job.postingDate['#cdata-section']).toLocaleDateString(
                'en-US',
                {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                }
              )}
            </div>
          </div>
          <div className="share">
            <span className="job-meta__label">Share</span>
            <SocialShare job={job} location="header" />
          </div>
        </div>
      </header>
    )
  };

  printJob = job => {
    return (
      <div className="job-content__body" itemProp="description">
        {this.formatDescription(job.jobDescription['#cdata-section']).map(
          (content, index) => {
            return (
              <section
                className="job-post__section"
                key={index}
                dangerouslySetInnerHTML={{
                  __html: (content.heading || '') + content.content
                }}
              />
            )
          }
        )}

        <div> {job.requiredExperience['#cdata-section']} </div>
        <div> {job.requiredSkills['#cdata-section']} </div>
        <p className="job-content__redirect">To submit an application for this position, click the “Apply” button below, and you will be redirected to our secure recruitment portal.</p>
        {this.printButton(job)}
      </div>
    )
  };

  handleClick = job => {
    return `https://csis-openhire.silkroad.com/epostings/index.cfm?fuseaction=app.presubmission&amp;gotofuse=app.res_login&amp;id=23&amp;jobid=${
      job.jobId['#text']
    }&amp;company_id=17233&amp;version=1&amp;jobowner=992538&amp;aid=0&amp;fid=0&amp;bystate=0&amp;bylocation=NULL&amp;bycountry=0&amp;bycat=&amp;keywords=`
  };

  printButton = job => {
    return (
      <a className="btn dark apply" href={`https://jobs.silkroad.com/CSIS/Careers/MultiForm/Apply?jobId=${
        job.jobId['#text']
      }&noFormRedirect=True`}>
        Apply
      </a>
    )
  };

  render() {
    const { jobData } = this.props
    const { pageContent, page } = this.state

    const job = jobData.find(
      job => job.jobId['#text'] === this.props.match.params.id
    )
    if (jobData.length && !job) return <Redirect to="/404" />

    return (
      <main className={page} itemScope itemType="http://schema.org/JobPosting">
        {job ? this.printHeading(job) : <header className="job-header" />}
        <article className="job-content">
          {job ? (
            this.printJob(job)
          ) : (
            <div className="job-content__body">
              <div className="loader" />
            </div>
          )}
          <aside className="job-content__about">
            <p className="job-content__redirect">To submit an application for this position, click the “Apply” button below, and you will be redirected to our secure recruitment portal.</p>
            {job ? this.printButton(job) : ''}
            <h3>About Us</h3>
            <img src={logo} className="Page-logo" alt="logo" />
            {MarkdownToSections(pageContent)}
          </aside>
        </article>
        {job ? <PostFooterNav job={job} /> : ''}
      </main>
    )
  }
}

/* eslint-disable */
Array.prototype.flatten = function() {
  var ret = []
  for (var i = 0; i < this.length; i++) {
    if (Array.isArray(this[i])) {
      ret = ret.concat(this[i].flatten())
    } else {
      ret.push(this[i])
    }
  }
  return ret
}
/* eslint-enable */
export default Post
