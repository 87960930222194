import React, { Component } from 'react'
import Header from './layout/Header'
import Footer from './layout/Footer'
import Page from './pages/Page'
import Opportunities from './pages/Opportunities'
import Post from './pages/Post'
import PostApp from './pages/PostApp'
import SiteMap from './SiteMap'
import XmlToJson from './helpers/XmlToJson'
import { Route, Switch } from 'react-router-dom'
import SmoothScroll from 'smooth-scroll'
import { withRouter } from 'react-router-dom'

const proxyUrl = '/.netlify/functions/cors/'
const targetUrl =
  'https://csis-openhire.silkroad.com/api/index.cfm?fuseaction=app.getJobListings&FORMAT=xml&JOBPLACEMENT=external'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      jobData: [],
      categories: [],
      filteredCategories: [],
      siteStructure: SiteMap
    }
  }

  updateJobs = (input, value, param) => {
    let filteredJobData, filteredCategories

    switch (input) {
    case 'search':
      filteredJobData = this.state.jobData.filter(data =>
        JSON.stringify(data)
          .toLowerCase()
          .includes(value.toLowerCase())
      )

      filteredCategories = [
        ...new Set(
          filteredJobData.map(data => {
            return data.category['#cdata-section']
          })
        )
      ]

      break

    case 'clear':
      filteredCategories = []
      filteredJobData = this.state.jobData
      break

    case 'category':
      filteredCategories = this.state.filteredCategories

      if (!param) {
        filteredCategories = filteredCategories.includes(value)
          ? filteredCategories.filter(
            cat => cat.toLowerCase() !== value.toLowerCase()
          )
          : [...filteredCategories, value]
      } else {
        filteredCategories = [value]
      }

      filteredJobData = filteredCategories.length
        ? this.state.jobData.filter(job =>
          filteredCategories.includes(job.category['#cdata-section'])
        )
        : this.state.jobData

      break
    default:
    }

    this.setState({ filteredJobData, filteredCategories })
  }

  componentDidMount() {
    const { siteStructure } = this.state
    console.log(proxyUrl + targetUrl)
    console.log(targetUrl)
    fetch(proxyUrl + targetUrl)
      .then(response => {
       
        if (response.ok) {
          return response.text()
        }
        throw new Error('Network response was not ok.')
      })
      .then(str => new DOMParser().parseFromString(str, 'text/xml'))
      .then(data => {
        if (XmlToJson(data).jobs && XmlToJson(data).jobs.job) {
          return XmlToJson(data).jobs.job.length ? XmlToJson(data).jobs.job : []
        } else {
          return []
        }
      })
      .then(jobData => {
        const categories = [
          ...new Set(
            jobData.map(data => {
              return data.category['#cdata-section']
            })
          )
        ]

        this.setState(
          {
            siteStructure,
            jobData,
            categories,
            filteredJobData: jobData,
            filteredCategories: []
          }
          //, () => console.log(this.state)
        )
      })
      .catch(error => console.log(error))

    new SmoothScroll('a[href*="#"]', {
      header: '.site-header',
      speed: 500
    })
  }

  render() {
    const {
      siteStructure,
      jobData,
      filteredJobData,
      categories,
      filteredCategories
    } = this.state

    return (
      <div className="wrapper">
        <Header siteStructure={siteStructure} />{' '}
        <Switch>
          <Route
            exact
            path="/"
            key="homepage"
            render={props => (
              <Page {...props} siteStructure={siteStructure} page="homepage" />
            )}
          />
          <Route
            exact
            path="/faq"
            key="faq"
            render={props => (
              <Page {...props} siteStructure={siteStructure} page="faq" />
            )}
          />
          <Route
            exact
            path="/internships"
            key="internships"
            render={props => (
              <Page
                {...props}
                siteStructure={siteStructure}
                page="internships"
              />
            )}
          />
          <Route
            exact
            path="/opportunities"
            render={props => (
              <Opportunities
                {...props}
                siteStructure={siteStructure}
                filteredJobData={filteredJobData}
                categories={categories}
                filteredCategories={filteredCategories}
                updateJobs={this.updateJobs}
                page="opportunities"
              />
            )}
          />
          <Route
            exact
            path="/opportunities/:id"
            render={props => (
              <Post
                {...props}
                siteStructure={siteStructure}
                jobData={jobData}
                page="post"
              />
            )}
          />
          <Route
            exact
            path="/opportunities/:id/apply"
            render={props => (
              <PostApp
                {...props}
                siteStructure={siteStructure}
                jobData={jobData}
                page="post"
              />
            )}
          />
          <Route
            render={props => (
              <Page
                key="error"
                {...props}
                siteStructure={siteStructure}
                page="errorpage"
              />
            )}
          />
        </Switch>
        <Footer siteStructure={siteStructure} />{' '}
        <div className="content-overlay" />{' '}
      </div>
    )
  }
}

export default withRouter(App)
