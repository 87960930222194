import React from 'react'
import GetData from '../helpers/GetData'
import ValueToJSX from '../helpers/ValueToJSX'
import logo from '../../assets/images/csis_logo_black.svg'

export default class Footer extends React.Component {
  render() {
    const FooterContent = GetData('footer')
    const { siteStructure } = this.props
    const menuItems = Object.keys(siteStructure)
      .filter(i => siteStructure[i].menu !== false)
      .sort((a, b) => siteStructure[a].index - siteStructure[b].index)

    const links = menuItems.map(key => {
      const link = siteStructure[key]
      return (
        <li key={link.index}>
          <a className="nav-link" href={link.to}>
            {link.footer_title}
          </a>
        </li>
      )
    })

    return (
      <footer className="site-footer">
        <section className="site-footer__info">
          <a
            href="https://www.csis.org/"
            target="_blank"
            rel="noopener noreferrer"
            className="csis-link"
          >
            <img
              src={logo}
              className="csis-logo"
              alt="Center for Strategic and International Studies"
              title="Center for Strategic and International Studies"
            />
          </a>

          {ValueToJSX(FooterContent.info.address)}
          <div className="site-footer__info__copyright">
            {ValueToJSX(FooterContent.info.copyright)}
          </div>
        </section>

        <section className="site-footer__details">
          <ul className="site-footer__details-social">
            <li>
              <a
                href="https://www.facebook.com/CSIS.org/"
                className="icon icon-facebook"
              >
                <span className="visually-hidden">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/CSIS" className="icon icon-twitter">
                <span className="visually-hidden">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/csis"
                className="icon icon-linkedin"
              >
                <span className="visually-hidden">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCr5jq6MC_VCe1c5ciIZtk_w"
                className="icon icon-youtube"
              >
                <span className="visually-hidden">Youtube</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/csis/?hl=en"
                className="icon icon-instagram"
              >
                <span className="visually-hidden">Instagram</span>
              </a>
            </li>
          </ul>

          <div className="site-footer__subsection">
            <section>
              <h4 className="site-footer__subsection-title">CSIS Careers</h4>

              <ul className="site-footer__subsection-nav nav">{links}</ul>
            </section>

            <section>
              <h4 className="site-footer__subsection-title">
                Office of Human Resources
              </h4>

              <div className="site-footer__subsection-content">
                {Object.keys(FooterContent.contact).map(key => {
                  return ValueToJSX(FooterContent.contact[key], null, key)
                })}
              </div>
            </section>
          </div>
        </section>
      </footer>
    )
  }
}
