import React from 'react'
import MarkdownToSections from '../helpers/MarkdownToSections'
import ValueToJSX from '../helpers/ValueToJSX'
import PageContent from '../helpers/PageContent'
import GetData from '../helpers/GetData'
import CloseMenu from '../helpers/CloseMenu'

class Opportunities extends React.Component {
  constructor(props) {
    super(props)
    const { siteStructure, page } = this.props

    const pageContent = PageContent(siteStructure, page, GetData(page))

    this.state = {
      siteStructure,
      pageContent,
      page,
      title: GetData(page).title
    }
  }

  handleCategoryChange = e => {
    const { location, history } = this.props

    const categoryParam = location.search.replace('?category=', '')

    if (categoryParam === 'internships') {
      history.push('/opportunities')
    }

    this.props.updateJobs('category', e.target.name)
    this.setState({ filtered: true })
  }

  handleClear = e => {
    const { location, history } = this.props
    const categoryParam = location.search.replace('?category=', '')

    if (categoryParam === 'internships') {
      history.push('/opportunities')
    }

    this.props.updateJobs('clear')
    this.setState({ query: '', filtered: false })
  }

  handleSearch = e => {
    this.props.updateJobs('search', e.target.value)
    this.setState({ query: e.target.value })
  }

  componentDidMount() {
    document.title = `${this.state.title} | CSIS Careers`
  }

  componentDidUpdate(prevProps, prevState) {
    const { queried, filtered } = this.state
    const { filteredJobData, location } = this.props
    const categoryParam = location.search.replace('?category=', '')

    if (categoryParam === 'internships' && !queried && filteredJobData.length) {
      this.props.updateJobs('category', 'Internships', true)
      this.setState({ queried: true, filtered: true })
    } else if (prevState.queried && !categoryParam && !filtered) {
      this.props.updateJobs('clear')
      this.setState({ queried: false })
    }
  }

  componentWillUnmount() {
    this.props.updateJobs('clear')
    const triggers = Array.from(
      document.querySelectorAll('.menu-trigger.is-active')
    )
    triggers.forEach(trigger => {
      const target = document.querySelector(trigger.dataset.target)
      CloseMenu(trigger, target)
    })

    window.scrollTo({
      top: 0
    })
  }

  render() {
    const { filteredJobData, categories, filteredCategories } = this.props
    const { page, pageContent, filtered, query } = this.state

    const header = pageContent.find(content => content.component === 'header')
    const headerContent = header ? header.content : {}
    const otherContent = pageContent.filter(
      content => content.component !== 'header'
    )

    return (
      <main className={page}>
        <section id="#header">
          <div className="header">
            {Object.keys(headerContent).map(content => {
              return ValueToJSX(
                headerContent[content],
                `header__${content}`,
                content
              )
            })}
          </div>
        </section>
        <div id="listings">
          <section className="listings__filters">
            <div className="listings__filters-search">
              <label htmlFor="job-search" className="filters-label">
                Filter by keyword
              </label>
              <div className="listings__filters-search-box">
                <input
                  id="job-search"
                  type="search"
                  placeholder="Search"
                  onChange={this.handleSearch}
                  value={query}
                />
              </div>
            </div>

            <div className="listings__filters-category">
              <input type="checkbox" id="filter-trigger" />

              <label htmlFor="filter-trigger" className="filters-label">
                Filter by category
              </label>

              <ul className="listings__filters-category_list">
                {categories.map(category => {
                  return (
                    <li key={category}>
                      <label
                        className={
                          filtered && filteredCategories.includes(category)
                            ? 'checked'
                            : ''
                        }
                      >
                        <input
                          type="checkbox"
                          checked={filteredCategories.includes(category)}
                          name={category}
                          onChange={this.handleCategoryChange}
                        />
                        <span className="label">{category}</span>
                      </label>
                    </li>
                  )
                })}
              </ul>
              <button className="block clear-all" onClick={this.handleClear}>
                Clear All
              </button>
            </div>
            <p className="listings__filters-summary">
              {filteredCategories.length} categories selected
            </p>
          </section>
          <section className="listings__results">
            <h2 className="listings__results-summary">
              {filteredJobData ? filteredJobData.length : ''} Results
            </h2>
            <ul className="listings__results-list">
              {filteredJobData && filteredJobData.length ? (
                filteredJobData.map(data => {
                  return (
                    <li key={data.jobId['#text']} className="job">
                      <h4 className="job__title">
                        <a
                          className="large"
                          href={'/opportunities/' + data.jobId['#text']}
                        >
                          {data.title['#cdata-section']}
                        </a>
                      </h4>
                      <div className="job__location">
                        {data.jobLocation.municipality['#cdata-section']},{' '}
                        {data.jobLocation.region['#cdata-section']}
                      </div>
                      <div className="job-meta">
                        <div className="job-meta__tracking">
                          <span className="job-meta__label">
                            Tracking Code:
                          </span>{' '}
                          {data.trackingCode['#cdata-section']}
                        </div>
                        <div className="job-meta__category">
                          <span className="job-meta__label">Category:</span>{' '}
                          {data.category['#cdata-section']}
                        </div>
                      </div>
                    </li>
                  )
                })
              ) : filteredJobData && filteredJobData.length === 0 ? (
                <div>
                  <p>No results were found.</p>
                  <p>
                    If you feel that you received this notice in error try
                    modifying your search term or filter results by another
                    category.
                  </p>{' '}
                </div>
              ) : (
                <div className="loader" />
              )}
            </ul>
          </section>
        </div>
        {MarkdownToSections(otherContent)}
      </main>
    )
  }
}

export default Opportunities
